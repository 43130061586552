import React from 'react';
import type { GetServerSideProps } from 'next';
import Home from '../features/Home';

const cacheTime = process.env.CACHE_TIME;

export default function StartPage() {
  return <Home />;
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  ctx.res.setHeader(
    'Cache-Control',
    `public, max-age=${cacheTime}, stale-while-revalidate=${cacheTime}, stale-if-error=86400`,
  );

  return { props: {} };
};
